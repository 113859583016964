.Contact {
  width: 100%;
  height: 100vh;
}
@media screen and (max-width:1400px) {
    .Contact {
        height: 100%;
    }
}


.containerContact div {
    margin-top: 20px;
}
.containerContact div img {
   padding: 15px;
   border-radius: 5px;
   background: rgb(136, 20, 20);
   background: linear-gradient(211deg, rgb(32, 32, 32) 0%, rgba(33,31,31,1) 51%, rgb(43, 39, 39) 100%);
}




.formContact {
    width: 80%;
    height: 500px;
    border-radius: 10px;
    
    align-items: center;
}

.formContact .textinput {
    width: 100%;
    margin-top: 12px;
    padding: 10px;
    border: none;
    border-radius: 5px;
}

.formContact button {
    width: 100%;
    margin-top: 20px;
    padding: 5px;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 600;
    color: rgb(18, 18, 19);

    position: relative;
    overflow: hidden;
    z-index: 1;
}

.formContact button:hover {
    color: #fff;
}

.formContact button::before,
.formContact button::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.formContact button::before {
  background-image: linear-gradient(45deg, rgb(236,129,6) 0%, rgb(212, 99, 6) 25%, rgb(248, 151, 65) 100%);
  z-index: -1;
  transition: opacity 240ms linear;
  opacity: 0;
}

.formContact button::after {
  content: '';
  height: 150%;
  width: 0.25em;
  background-color: #fff;
  box-shadow: 0px 0px 8px 4px #fff;
  transform: translateX(-1em);
}

.formContact button:hover::before {
  opacity: 1;
}

.formContact button:hover::after {
  animation-duration: 300ms;
  animation-name: sweep;
}

@keyframes sweep {
  from { 
    transform: translateX(-1em)
  }
  to { 
    transform: translateX(30.5em);
    animation-timing-function: ease-out; 
  }
}
