.scroll-reveal h2 {
    font-size: 3rem;
    line-height: 1;
    font-weight: 900;
}
.scroll-reveal p {
    font-size: 1.75rem;
    line-height: 1.3;
    max-width: 55ch;
    margin-inline: auto;
    font-weight: 600;
}

@media screen and (max-width:560px) {
    .scroll-reveal p {
        padding: 0 5px;
        text-align: justify;
    }
}
.scroll-reveal  * {
    color: hsl(0 0% 100% / 0.2);
    background-clip: text;
    background-repeat: no-repeat;
    background-size: 0% 100%;
    background-image:   linear-gradient(90deg, rgba(255, 0, 0, 0.39) 16%, rgba(194,183,13,1) 85%);
    /* background-image: url('https://images.pexels.com/photos/36717/amazing-animal-beautiful-beautifull.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'); */
    -webkit-animation: scroll-reveal linear forwards;
    animation: scroll-reveal linear forwards;
    animation-timeline: view();
    -webkit-animation-timeline: view();
}

.scroll-reveal h2  {
    animation-range-start: contain 20vh;
    animation-range-end: contain 40vh;
}
.scroll-reveal p  {
    animation-range-start: contain 10vh;
    animation-range-end: contain 70vh;
}


@keyframes scroll-reveal {
    to {
        background-size: 100% 100%;
    }
}


/* Changeitem component */

.Hover {
    opacity: 100;
    transition: 0.5s;
    height: 360px;
}

/* Main two */

.MainTwo {
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
    z-index: 4;
    background-color: black;
}

.Animation {
    animation: Animation 2s linear infinite both;
}

@keyframes Animation {
    0% {margin-top: 0;}
    50% {margin-top: -18px}
    100% {margin-top: 0}
}