.container h1 {
    font-size: 60px;
}
@media screen and (max-width: 1400px) {
    .container h1 {
        font-size: 40px;
    }
}
@media screen and (max-width: 477px) {
    .container h1 {
        font-size: 35px;
    }
}
@media screen and (max-width: 433px) {
    .container h1 {
        font-size: 20px;
    }
}