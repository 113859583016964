.container_image_page {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    padding-top: 100px;
}

.container_image_page .box_image {
    width: 300px;
    height: 150px;
    margin: 5px;
}

.container_image_page .box_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

h1 {
    font-size: 2em;
    text-align: center;
    margin-bottom: 20px;
}