.DarkMode {
    width: 50px;
    height: 30px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #000;
    border-radius: 50px;
}

.DarkMode button {
    width: 100%;
    height: 100%;
    border:  none !important;
    background-color: transparent !important;;
    font-size: 20px !important;
}

   