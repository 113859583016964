.ContactPrivate {
    width: 100%;
    min-height: 90vh;
    padding-top: 100px;
    
    .ContactForm {
        min-width: 300px;
        height: 400px;
        margin: 10rem auto;
        border: solid 1px white;

    }
}